import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchMinistries } from '../components/contentfulFiles';
import '../styles/ministrypage.css';
import Navbar from '../components/navbar';
import Arrow from '../assets/arrow.png'; // Importing the arrow image
import BackgroundImage from '../assets/message_bg.png'; // Importing the background image
import Footer from '../components/footer';

const Ministrypage = () => {
  const { urlname } = useParams();
  const [ministry, setMinistry] = useState(null);

  useEffect(() => {
    const getMinistry = async () => {
      const ministriesData = await fetchMinistries();
      const ministry = ministriesData.find(min => min.fields.urlname === urlname);
      setMinistry(ministry);
    };

    getMinistry();
  }, [urlname]);

  if (!ministry) {
    return <div>Loading...</div>;
  }
  return (
    <div className="ministrypage">
      <Navbar />
      <main>
        <section
          className="hero"
          style={{
            backgroundImage: `url(${ministry.fields.hero.fields.file.url})`,
          }}
        >
        </section>
        <div 
          className="content" 
          style={{ backgroundImage: `url(${BackgroundImage})` }} // Apply the background image here
        >
          <h1>{ministry.fields.title}</h1>
        <div className="content-container">
          <div className="content-left">
            <h2>Mission</h2>
            <p>{ministry.fields.mission}</p>
          </div>
          <div className="content-right">
            <h2>Message</h2>
            <p>{ministry.fields.message}</p>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
);
};

export default Ministrypage;
