import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../styles/slideshow.css";

// Require all images from the src/images directory
function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("../images", false, /\.(png|jpe?g|svg)$/)
);

const imageItems = images.map((image) => ({
  original: image,
  thumbnail: image,
}));

const Slideshow = () => {
  return (
    <main className="main">
      <div className="container">
        <h1 className="title">View our beautiful family in pictures</h1>
      </div>

      <div className="container">
        {imageItems.length > 0 ? (
          <ImageGallery
            items={imageItems}
            additionalClass="react-image-gallery"
            autoPlay={true} // Enable auto-play
            slideInterval={7000}
          />
        ) : (
          <p>Loading images...</p>
        )}
      </div>
    </main>
  );
};

export default Slideshow;
