import React from "react";
import Navbar from "../components/navbar";
import { useState, useEffect } from "react";
import { fetchPodcasts } from "../components/contentfulFiles";
import { Link } from "react-router-dom";
import "../styles/podcasts.css";
import Footer from "../components/footer";
import backgroundImage from "../assets/podcast_hero.jpg";
import withScroll from "../components/scroll";

const Podcasts = ({ sectionRef, handleScroll }) => {
  const [podcasts, setPodcasts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [podcastsPerPage] = useState(5);

  useEffect(() => {
    const getPodcasts = async () => {
      const podcastdata = await fetchPodcasts();
      setPodcasts(podcastdata);
    };

    getPodcasts();
  }, []);
  const getYoutubeThumbnail = (url) => {
    const videoId = url.split("v=")[1];
    const ampersandPosition = videoId ? videoId.indexOf("&") : -1;
    return `https://img.youtube.com/vi/${
      ampersandPosition !== -1
        ? videoId.substring(0, ampersandPosition)
        : videoId
    }/0.jpg`;
  };
  const filteredPodcasts = podcasts.filter((podcast) =>
    podcast.fields.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    podcast.fields.speaker.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastPodcast = currentPage * podcastsPerPage;
  const indexOfFirstPodcast = indexOfLastPodcast - podcastsPerPage;
  const currentPodcasts = filteredPodcasts.slice(indexOfFirstPodcast, indexOfLastPodcast);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const sortedPodcasts = [...currentPodcasts].sort((a, b) => new Date(b.fields.sorttime) - new Date(a.fields.sorttime));

  const generateSlug = (title) => {
    return title.toLowerCase().replace(/[\s_]+/g, "-");
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="podcasts-page">
      <Navbar />
      <main>
        <section className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="hero-content">
                <h1>Listen to our latest podcasts</h1>
            </div>
            <div className="podcast-hero-buttons">
                <button className="btn-podcasts" onClick = {handleScroll}>Listen Now</button>
                <Link to ="https://www.youtube.com/@Cfnsda" className="btn-podcasts-secondary">Subscribe</Link>
            </div>


        </section>
       
        <section ref = {sectionRef} className="podcasts-map">
          <h1>Latest Podcasts</h1>
          <input
            type="text"
            placeholder="Search podcasts..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-bar"
          />
         {sortedPodcasts.length > 0 ? (
            sortedPodcasts.map((podcast) => (
              <div key={podcast.sys.id} className="podcast-item">
                <Link to={`/podcasts/${generateSlug(podcast.fields.title)}`}>
                  <img
                    src={getYoutubeThumbnail(podcast.fields.pageUrl)}
                    alt={podcast.fields.title}
                    className="podcast-thumbnail"
                  />
                </Link>
                <div className="podcast-info">
                  <Link to={`/podcasts/${generateSlug(podcast.fields.title)}`}>
                    <h2>{podcast.fields.title}</h2>
                  </Link>
                  <h3>{podcast.fields.speaker}</h3>
                  <h3>{podcast.fields.date}</h3>
                </div>
              </div>
            ))
          ) : (
            <p>No podcasts available</p>
          )}
        </section>

        <div className="pagination">
          {[...Array(Math.ceil(filteredPodcasts.length / podcastsPerPage)).keys()].map(number => (
            <button key={number} onClick={() => paginate(number + 1)} className="page-number">
              {number + 1}
            </button>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default withScroll(Podcasts);