import React, { useState } from 'react';
import { uploadAsset, createEntry } from '../components/contentfulFiles';

const BulkImageUploader = () => {
  const [images, setImages] = useState([]);

  const handleFileChange = (event) => {
    setImages(event.target.files);
  };

  const handleUpload = async () => {
    for (const image of images) {
      try {
        const asset = await uploadAsset(image);
        await createEntry(asset.sys.id, image.name);
        console.log(`Uploaded and created entry for ${image.name}`);
      } catch (error) {
        console.error(`Error uploading ${image.name}: `, error);
      }
    }
  };

  return (
    <div>
      <input type="file" multiple onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload Images</button>
    </div>
  );
};

export default BulkImageUploader;