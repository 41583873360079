import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import backgroundImage from "../assets/events_bg.png"
import "../styles/events.css"
import CalendarComponent from '../components/calendar'
import withScroll from '../components/scroll'
import { Helmet } from 'react-helmet'

const EventsPage = ({ sectionRef, handleScroll }) => {
  return (
    <div className= "calvary-events">
      <Helmet>
        <title>Events - Join our events</title>
      </Helmet>
      <Navbar />
      <main>
        <section className="events-hero" style={{
            backgroundImage: `url(${backgroundImage})`,
          }}>
          <div className="events-hero-content">
            <h1>Like to join
            <br/>our events?
            </h1>
            </div>
            <div className="events-hero-buttons">
              <button className="btn" onClick={handleScroll}>Join Now</button>
            </div>
          </section>
          <section ref={sectionRef} className="events-calendar">
            <h2>Events Calendar</h2>
            <CalendarComponent />
          </section>
      </main>
      <Footer />
    </div>
  )
}

export default withScroll(EventsPage);