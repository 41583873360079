import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import { fetchAnnouncements } from '../components/contentfulFiles';
import '../styles/announcements.css';
import backgroundImage from '../assets/announcement.avif';
import { handleScroll } from '../components/scroll';
import withScroll from '../components/scroll';
import { Helmet } from 'react-helmet';
const Announcements = ({ sectionRef, handleScroll }) => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const getAnnouncements = async () => {
      const fetchedAnnouncements = await fetchAnnouncements();
      setAnnouncements(fetchedAnnouncements);
    };
    getAnnouncements();
  }, []);

  const toggleReadMore = (index) => {
    setAnnouncements((prevAnnouncements) =>
      prevAnnouncements.map((announcement, i) =>
        i === index
          ? { ...announcement, isExpanded: !announcement.isExpanded }
          : announcement
      )
    );
  };

  return (
    <div className='announcements-page'>
      <Helmet>
        <title>Announcements - Stay up to date with our latest announcements</title>
      </Helmet>
      <Navbar />
      <main>
        <section
          className="hero"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="hero-content">
            <h1>
              Stay up to date
              <br />
              with our latest announcements
            </h1>
          </div>
          <div className="hero-buttons">
            <button className="btn" onClick={handleScroll}>Read More</button>
          </div>
        </section>
        <section ref={sectionRef} className="announcements-container">
          <h1>Announcements</h1>
          {announcements.length > 0 ? (
            announcements.map((announcement, index) => (
              <div key={index} className="announcement">
                <p className="announcement-title">{announcement.fields.title}</p>
                <p className="announcement-date">{announcement.fields.announcedate}</p>
                <p className="announcement-body">
                  {announcement.isExpanded
                    ? announcement.fields.text
                    : `${announcement.fields.text.substring(0, 0)}...`}
                </p>
                <p
                  className="announcement-read-more"
                  onClick={() => toggleReadMore(index)}
                >
                  {announcement.isExpanded ? 'Show Less' : 'Read More'}
                </p>
              </div>
            ))
          ) : (
            <p>No announcements available.</p>
          )}
        </section>
      </main>
    </div>
  );
};

export default withScroll(Announcements);