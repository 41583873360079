import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import { fetchPodcasts } from "../components/contentfulFiles";
import { useParams } from "react-router-dom";
import Footer from "../components/footer";
import { DiscussionEmbed } from "disqus-react";
import { CommentCount } from "disqus-react";
import '../styles/podcastpage.css';


const Podcastcontent = () => {
  const { titleSlug } = useParams();
  const [podcast, setPodcast] = useState(null);

  useEffect(() => {
    const getPodcast = async () => {
      const podcastsData = await fetchPodcasts();
      const podcast = podcastsData.find(
        (pod) =>
          pod.fields.title.toLowerCase().replace(/[\s_]+/g, "-") === titleSlug
      );
      setPodcast(podcast);
    };

    getPodcast();
  }, [titleSlug]);

  // Conditional rendering to ensure the podcast data is available
  if (!podcast) {
    return <div>Loading...</div>; // Display a loading state or spinner while data is being fetched
  }

  return (
    <div className="podcast-detail">
      <Navbar />
      <main>
        <h1>{podcast.fields.title}</h1>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${
            podcast.fields.pageUrl.split("v=")[1]
          }`}
          title={podcast.fields.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </main>
      <DiscussionEmbed
          shortname="calvarypodcasts"
          config={{
            url: window.location.href, // Use the current page URL
            identifier: podcast.fields.id, // Use a unique identifier for the content
            title: podcast.fields.title, // The title of the podcast
            language: "en_US", // Specify the language for Disqus
          }}
        />
      <Footer />
    </div>
  );
};

export default Podcastcontent;
