
import React from 'react';
import '../styles/footer.css'; //css file for footer
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <h2>Calvary First Nigerian Seventh Day Adventist Church</h2>
        <p>778 Lexington Ave, Brooklyn, NY 11221</p>
      </div>
      <div className="footer-middle">
        <div className="footer-section">
          <h3>Church</h3>
          <ul>
          <li><Link to="/about">About Us</Link></li>
          <li><Link to="/events">Events</Link></li>
          <li><Link to="/online-giving">Donate</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to ="/sermons">Sermons</Link></li>
            <li><Link to ="/podcasts">Podcasts</Link></li>
            <li><Link to ="/contact">Contact</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-right">
        <div className="social-icons">
        <a href="https://www.youtube.com/@Cfnsda" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-youtube"></i>
          </a>
          <a href="https://www.facebook.com/groups/474591892604091/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <ul>
          <li>Legal</li>
          <li>Terms & Conditions</li>
          <li>Privacy Policy</li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
