import React from 'react'
import { Link } from 'react-router-dom'
import "../styles/prayer.css"
const Prayer = () => { 
return (
    <div className = "prayer">
         <h2>Have A Prayer Request?</h2>
          <p>Share your prayer requests with us. We are here to support
            and pray for you, offering comfort and strength in times of need.
          </p>
          <Link to="/contact" className="button">
            Send a prayer request
          </Link>
    </div>
)
}
export default Prayer