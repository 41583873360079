import React from "react";
import Navbar from "../components/navbar";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Prayer from "../components/prayer";
import backgroundImage from "../assets/backabout.jpg";
import "../styles/about.css"; //css file for about
import speakerImage from "../assets/preach.jpg";
import Sunset from "../assets/sunset.jpg";
import Read from "../assets/read.jpg";
import Church from "../assets/church.jpg";
import withScroll from "../components/scroll"; // Adjust the path as necessary
import { Helmet } from "react-helmet";

const About = ({ sectionRef, handleScroll }) => {
  return (
    <div className="about">
      <Helmet>
        <title>About - What do you know about us?</title>
      </Helmet>
      <Navbar />
      <main>
        <section
          className="hero"
          style={{
            backgroundImage: `url(${backgroundImage})`, //used as background
          }}
        >
          <div className="hero-content">
            <h1>
              What do you
              <br />
              know about us?
            </h1>
          </div>
          <div className="hero-buttons">
            <button className="btn" onClick={handleScroll}>
              Read about us
            </button>
            <Link to ="/contact" className="btn secondary">Reach out to us</Link>
          </div>
        </section>
        <section ref={sectionRef} className="message">
          <div className="image-container">
            <img src={speakerImage} alt="Speaker" />
          </div>
          <div className="message-text">
            <h2>Greetings from Church Pastor</h2>
            <p>
              Welcome to our CFNSDAC website, the finest digital sanctuary and
              spiritual oasis. You have discovered the center of innovation,
              diversity, and inclusivity. This platform was designed to
              stimulate creativity, imagination, and spiritual growth for all
              our prestigious users including you. We are delighted to have you
              as a part of our community. As a Christian community with a
              passionate mission for the world, we are delighted to serve all
              people, particularly, minority- rejected, marginalized, forgotten,
              and maligned groups. We are here to genuinely assist you at every
              stage of your journey. So, feel free to discover, interact, and
              collaborate with us to create the amazing experience and reality
              you have been seeking. You may drop a request for prayer in the
              section marked “Prayer Request Basket. You may also make a request
              for visitation and your contact details, and our pastoral team
              will contact you as soon as possible. Take a moment to enjoy the
              multiple services we provide such as the Pastor’s Blog, Music
              Videos, Sports Corner, Sermons, and Special Podcasts. before you
              exit, don’t forget to leave a comment or suggestion in the
              “comment box.” We pray that your experience today will shape the
              frequency with which you visit the CFNSDAC website.
            </p>
          </div>
        </section>
        <section className="who-we-are">
          <h2>About Calvary First Nigerian SDA Church</h2>
          <h3>
            Proverbs 22:6 - “Train up a child in the way he should go, and when
            he is old, he will not depart from it.”
          </h3>
          <p>
            Most of the members that comprise the Calvary S.D.A. Church are
            people who have been raised and nurtured in Adventist homes and
            educational institutions from home. However, upon reaching this
            country, because of the societal pressures and influences, most of
            us did not join any church, attending church sporadically, some
            attending church and some joined completely stopped non-Adventist
            churches. But the truth within and the indwelling spirit was too
            strong to ignore. Generally, there was the guilty feeling that we
            were back-sliding from the church and more importantly, that we were
            failing in our responsibility to raise our children in the church.
            Most of us were harboring the wish that we would get together with
            many other Nigerian Adventists here in the city to form a church.
          </p>
          <p>
            It was under the above setting that the Almighty God put together
            the Calvary SDA Church. I was sitting in my living room one fateful
            evening when the phone rang, it was brother Ephraim Ajibade. "I have
            been thinking - why can't we come together and form a church? I have
            spoken to Mr. Adebara and he agrees to lead us. He is very
            experienced, he used to work for the mission at Okebola". Of course,
            my answer was an enthusiastic "yes". Mr. Ajibade called others and
            got the same yes answer, and so a meeting date was set for May 21,
            1989. This meeting was attended by 11 people namely: Isaiah Oyedijo,
            Dele Ajibade, Oye Ajibade, Taiye Adeosun, Niyi Ajibade, Segun
            Adeleke, Nathaniel Ajibade, Phillip Adefajo, Adegbola Adeniran, Dele
            Adebara, and David Ajayi.
          </p>
          <p>
            At the end of the meeting, a formal agreement was reached to start a
            church using a day care center space at 800 Rogers Avenue. Voluntary
            donations amounting to $450.00 were collected on the spot for the
            purpose of purchasing chairs and other initial needs. In a
            subsequent meeting five others joined, they were: Eniola Alao,
            Oyeniyi Aina, Jacob Adesina, Isaac Ajaiyeoba, and Samson Dada. On a
            bright Saturday morning June 3, 1989, a group of 40 adults and
            children gathered at 800 Rogers Avenue to hold the first service of
            a congregation that would later be known as Calvary S.D.A. Church.
            Our first hymn of worship was #340 "Jesus Saves." Mr. Dele Adebara
            was elected as the first leader of the group, while Mr. Taye Adeosun
            was chosen as the first Secretary/Clerk, and Mr. Nathaniel Ajibade
            the first Treasurer.
          </p>
          <p>
            The experience of Calvary Church has been unique. This is a
            congregation that was put together by God's own Providence, nurtured
            and sustained by His awesome power. Through the years, through all
            our ups and downs, and through all our "teething" problems as a
            young congregation, we have seen the evidence of God's controlling
            power. God has been faithful to us. Because of the uniqueness of the
            church's beginning and the fact it did not come out of an existing
            church, we did not have a "mother church" to raise us and basically
            had to fend for ourselves. We tried an adoption process with Emeaus
            church but it did not work out.
          </p>
          <p>
            Another problem area was accommodation. We started at Rogers Avenue
            using a day care center space, but we were later evicted by the
            owner; then we joined with a small Sunday congregation to share a
            rented space, but one Sabbath we found ourselves locked out. Next,
            we got a small store front for a few weeks but we lost that too. On
            one occasion we had to use the basement of one of our members.
            Finally, we got a space at Senior Citizens Center at 966 Fulton
            Street Brooklyn from March 3, 1990, to May 2000. At that location,
            there were occasions when we got locked out - we had to use a space
            at Bethel Church on one occasion, at another time we sang and prayed
            on the sidewalk and then dispersed.
          </p>
          <p>
            The first leader of this group, Mr. Dele Adebara, stepped down after
            some time. Consequently, Mr. Eniola Alao succeeded him as the
            leader/superintendent of the group. Mr. Alao was an energetic and
            dedicated leader who worked very hard to get the church going.
            Unfortunately, he suddenly took ill and passed away. This event
            proved to be the most traumatic in our experience and threatened the
            very existence of the group. The sorrow was quite deep and the
            discouragement was obvious. Eniola Alao has departed from us, but
            his memory will always remain with us. At this low point in our
            existence, the Lord came to the rescue.
          </p>
          <p>
            At Mr. Alao's funeral, we met Sister Patricia Langley, a dynamic and
            energetic Bible worker with the North Eastern Conference of
            Seventh-Day Adventists. She saw our pain and anguish and the
            devastation of the group, and sensed that this was a group of people
            in dire need of help, and she promised to help. The next Sabbath,
            Sister Pat (as she was affectionately called), was the first to
            arrive at 966 Fulton Street. Sister Langley's energy level was
            contagious. Her presence really energized the congregation and
            provided us with lots of positive publicity. We made good progress
            during her time, and it was under her leadership that we started
            seriously to contribute to a church building. We deeply appreciate
            her love and her efforts - she was help sent from heaven.
          </p>
          <p>
            During Sister Pat's time with us, Pastor Ali Sinclaire joined us,
            working with Sister Pat. When Sister Langley left us, Pastor
            Sinclaire remained and continued to work with us until he too left
            about a year later. We thank Pastor Sinclaire for his services among
            us. With Pastor Sinclaire's exit, there was a definite vacuum for
            pastoral leadership. The elders did their best to keep the church
            going. Our main focus has been to get a permanent address, to get an
            identity, to have a place where we can call our own and
            conspicuously display our signboard. We went all over Brooklyn and
            sometimes Queens, looking for suitable buildings for a church, but
            we were never able to agree on any of them.
          </p>
          <p>
            Then came the modern day Joshua, whom God had ordained to lead
            Calvary Church to the promised land; a man who, like Joshua of old,
            believes that though there may be giants in the way, they could be
            overcome through the power of the Almighty. His name is Pastor Larry
            Bailey. Pastor Bailey was assigned to us by the North Eastern
            Conference President, and even though he had other big
            responsibilities with the conference and was with us on a part-time
            basis, he made a tremendous difference in the church. This
            calm-mannered, soft-spoken planner and visionary leader became the
            "hub that turned the wheel of progress" as a congregation under the
            guidance of the omnipotent God. Pastor Bailey joined us in the
            search for a church building.
          </p>
          <p>
            Then we came to Lexington Avenue to check on two buildings - 778 and
            811. We fell in love with the idea of acquiring these two buildings
            facing each other; and the parking lot was an added enticement.
            After we checked through these properties, we all gathered in front
            of 778 and suggested that we hold hands and pray. We petitioned the
            Almighty God to give us these properties, and He did. Not only did
            God give all three properties, He also provided the means to
            renovate the 778 building.
          </p>
          <p>
            As we were planning for renovation of the church building, God came
            through for us again, as usual. He provided the needed help to put
            the plans together and execute them. Mr. Banji Awosika (Reg.
            Architect) joined the church, took over the structural design and
            the coordination of the project, and as a result of his efforts, we
            were able to move into our building on May 6, 2000. The building was
            dedicated on June 24, 2000. We thank God for Mr. Awosika, and pray
            that God will bless him, his job, and family.
          </p>
          <p>
            As we look back at the last twelve years and remember the many good
            things the Lord has been able to do for us and through us as a
            congregation, we are encouraged to boldly face the future with
            confidence and with hope in our hearts for an even better tomorrow.
            Our youth are in the church and working for the Master, we have
            baptized 7 members in the last 9 months, and we have witnessed
            several weddings among our youth. Currently, our membership, youth,
            and children inclusive, stands at about 120. We will prayerfully
            forge ahead until we have fulfilled our commission here in Bedford
            Stuyvesant, then in Brooklyn, in the state and country as a whole.
          </p>
          <p>
            We thank the North Eastern Conference of SDA, our friends and
            well-wishers, and all those who have played a part in bringing us to
            this point in our existence. Recently, the church family voted to
            change the name of this congregation. The name chosen and approved
            by the Conference Administration is "First Nigerian Seventh-Day
            Adventist Church." Be it known, therefore, that as this
            congregation's status changes today from Mission to full-fledged
            church, its name will simultaneously change from Calvary Mission to
            First Nigerian Seventh-Day Adventist.
          </p>
          <p>
            We thank all who have come to celebrate this special occasion with
            us and pray for God's protection upon you as you depart to your
            homes. May the Lord shower on you His choicest blessing.
          </p>
        </section>
        <section className="our-mission">
          <h2>Our Local Church Mission</h2>
          <p>
            Welcome to our cherished sanctuary of love and worship. Amongst
            God's family, find solace, strength, and belonging. We rejoice in
            your presence, knowing that together, we journey in faith and love.
          </p>
        </section>
        <section
          className="belief-hero"
          style={{
            backgroundImage: `url(${Read})`, //used as background
          }}
        >
          <div className="belief-content">
            <h1 className="belief-overlay-text">Our Fundamental Beliefs</h1>
            <p className="belief-overlay-text">
              Wondering what we believe, why we worship the way we do?
            </p>
            <a
              href="https://www.adventist.org/beliefs/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-belief"
            >
              See our beliefs
            </a>
          </div>
        </section>
        <Prayer />
      </main>
      <Footer />
    </div>
  );
};

export default withScroll(About);
