import React, { useRef } from "react";
import Navbar from "../components/navbar";
import "../styles/contact.css";
import backgroundImage from "../assets/contact_bg.png";
import emailjs from "@emailjs/browser";
import Footer from "../components/footer";
import withScroll from "../components/scroll";
import { Helmet } from "react-helmet";
import { useState } from "react";

const Contact = ({ sectionRef, handleScroll }) => {
  const form = useRef();
  const [isAnonymous, setIsAnonymous] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xbass7h",
        "template_i8tm85o",
        form.current,
        "ghmqPSwlPgFKh5E6t"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Failed to send message, please try again.");
        }
      );
  };
  const handleCheckboxChange = (e) => {
    setIsAnonymous(e.target.checked);
  };

  return (
    <div className="contact">
      <Helmet>
        <title>Contact - Reach out to us</title>
      </Helmet>
      <Navbar />
      <main>
        <section
          className="contact-hero"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="contact-hero-content">
            <h1>Need to reach out to us?</h1>
          </div>
          <div className="contact-hero-buttons">
            <button className="btn" onClick={handleScroll}>
              Contact Us
            </button>
          </div>
        </section>
        <section ref={sectionRef} className="contact-form-section">
          <h2>Get in Touch</h2>
          <p>We would love to hear from you</p>
          <div className="form-group">
            <label htmlFor="anonymous">Submit as Anonymous</label>
              <input
                type="checkbox"
                id="anonymous"
                name="anonymous"
                onChange={handleCheckboxChange}
              />
            </div>
          <form className="contact-form" ref={form} onSubmit={sendEmail}>
            {!isAnonymous && (
              <>
                <div className="form-group">
                  <label htmlFor="firstname">First Name</label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="First name.."
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Last name.."
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your email.."
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Your phone number.."
                    required
                  />
                </div>
              </>
            )}

            <div className="form-group">
              <label htmlFor="message">Message/Prayer Request</label>
              <textarea
                id="message"
                name="message"
                placeholder="Write something.."
                required
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="message">Suggestions</label>
              <textarea
                id="suggestions"
                name="suggestions"
                placeholder="I think we should add..."
                required
              ></textarea>
            </div>
            <button type="submit" className="btn-submit">
              Send Message
            </button>
          </form>
        </section>
        <section className="contact-info">
            <h3>Our Location</h3>
            <p>778 Lexington Ave, Brooklyn, NY 11221</p>
            <p>Phone: (718)-919-9592</p>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6050.539140290802!2d-73.93073402513672!3d40.69006033896026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25c0cf14276c7%3A0x6237d5299d070105!2sCalvary%20First%20Nigerian%20Seventh-Day%20Adventist%20Church!5e0!3m2!1sen!2sus!4v1723608180772!5m2!1sen!2sus"
                width="450"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </section>
      </main>
      <Footer />
    </div>
  );
};
export default withScroll(Contact);