import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import backgroundImage from '../assets/giving.jpeg'
import '../styles/give.css'
import Zelle from '../assets/zelle.png'
import Envelope from '../assets/envelope.jpg'
import Paypal from '../assets/paypal.png'
import adventist from '../assets/adventistgiving.jpeg'
import withScroll from '../components/scroll'
import { Helmet } from 'react-helmet'

const Give = ({ sectionRef, handleScroll }) => {
    return (
        <div className='Giving'>
            <Helmet>
                <title>Give - Giving is receiving</title>
            </Helmet>
            <Navbar />
            <main>
            <section
          className="hero"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
                    <div className='hero-content'>
                    <h1>
              Did you know that
              <br />
              giving is receiving?
            </h1>
                    </div>
                    <div className='hero-buttons'>
                        <button className='btn' onClick={handleScroll}>Donate</button>

                    </div>
                </section>
                <section ref={sectionRef} className='how-to-give'>
                  <h2>Give Online</h2>
                  <div className='payment-methods'>
                  <a href="https://www.zellepay.com/how-it-works" target="_blank" rel="noopener noreferrer" className="payment-option">
                        <img src={Zelle} alt="Zelle" />
                        <p>Pay with Zelle</p>
                    </a>
                    <a href="https://www.paypal.com/donate" target="_blank" rel="noopener noreferrer" className="payment-option">
                        <img src={Paypal} alt="Paypal" />
                        <p>Pay with Paypal</p>
                    </a>
                    <a href="https://www.adventistgiving.org/" target="_blank" rel="noopener noreferrer" className="payment-option">
                        <img src={adventist} alt="Adventist" />
                        <p>Pay with Adventist Giving</p>
                    </a>
                    <div className="payment-option">
                        <img src={Envelope} alt="Envelope" />
                        <p>Mail a Check</p>
                        
                    </div>
                  </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default withScroll(Give);