import React, { useRef } from 'react';

// Utility function to scroll to a ref
const scrollToRef = (ref) => {
  ref.current.scrollIntoView({ behavior: 'smooth' });
};

// Higher-Order Component to add scrolling functionality
const withScroll = (WrappedComponent) => {
  return (props) => {
    const sectionRef = useRef(null);

    const handleScroll = () => {
      scrollToRef(sectionRef);
    };

    return (
      <WrappedComponent
        {...props}
        sectionRef={sectionRef}
        handleScroll={handleScroll}
      />
    );
  };
};

export default withScroll;
