import client from '../components/contentfulFiles'; 
import '../styles/bulletin.css'; //css file for bulletin
import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import { Helmet } from 'react-helmet';
import backgroundImage from '../assets/bulletin_hero.jpg';

//used to fetch the field names from the contentful API
const fetchFieldNames = async (setFieldNames, setError) => {
    try {
      const response = await client.getContentTypes();
      const fields = {};
      response.items.forEach(contentType => {
        contentType.fields.forEach(field => {
          fields[field.id] = field.name;
        });
      });
      setFieldNames(fields);
    } catch (error) {
      console.error('Error fetching content types:', error);
      setError('Failed to fetch content types.');
    }
  };
  
  function Bulletin() {
    const [sabbathSchoolEvents, setSabbathSchoolEvents] = useState([]);
    const [divineServiceEvents, setDivineServiceEvents] = useState([]);
    const [fieldNames, setFieldNames] = useState({});
    const [error, setError] = useState(null);
  
    useEffect(() => {
      fetchFieldNames(setFieldNames, setError);
      const fetchEvents = async (contentType, setEvents) => {
        try {
          const response = await client.getEntries({ content_type: contentType });
          const events = response.items.map(item => item.fields);
          setEvents(events);
        } catch (error) {
          console.error(`Error fetching ${contentType} events:`, error);
          setError(`Failed to fetch ${contentType} events.`);
        }
      };
  
      fetchEvents('sabbathSchool', setSabbathSchoolEvents); 
      fetchEvents('divineService', setDivineServiceEvents); 
    }, []);
  
    const renderEventContent = (events) => {
      return events.map((event, index) => (
        <div key={index} className="event">
          {Object.entries(event).map(([fieldId, value]) => (
            <div key={fieldId} className="event-field">
              <strong>{fieldNames[fieldId] || fieldId}:</strong>
              <p>{value}</p>
            </div>
          ))}
        </div>
      ));
    };
  
    return (
      <div>
        <Navbar />
        <main>
          <section className="bulletin-hero" style={{
          backgroundImage: `url(${backgroundImage})`,
        }}>
          <div className="hero-content">
            <h1>
              View our bulletin
            </h1>
          </div>
        </section>
        <div className="bulletin-container">
          <Helmet>
            <title>Bulletin - View our bulletin</title>
          </Helmet>
          {error && <div className="error">{error}</div>}
          <div className="section sabbath-school">
            <h1>Sabbath School</h1>
            {sabbathSchoolEvents.length > 0 ? (
              renderEventContent(sabbathSchoolEvents)
            ) : (
              <p>No Sabbath School events available.</p>
            )}
          </div>
          <div className="section divine-service">
            <h1>Divine Service</h1>
            {divineServiceEvents.length > 0 ? (
              renderEventContent(divineServiceEvents)
            ) : (
              <p>No Divine Service events available.</p>
            )}
          </div>
        </div>
       </main>
      </div>
    );
  }
  export default Bulletin;