import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import '../styles/swipercomponent.css'

const SwiperComponent = ({ events }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      autoplay={{ delay: 10000 }} // Auto slide every 10 seconds
    >
      {events.map((event) => (
        <SwiperSlide key={event.sys.id}>
          <div className="event">
            <div className="event-details">
              <h3>{event.fields.eventName}</h3>
              <p>{event.fields.eventDescription}</p>
              <p>Time: {event.fields.time} Date: {event.fields.date}</p>
            </div>
            <img src={event.fields.image.fields.file.url} alt={event.fields.eventName} className="event-image" />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperComponent;
