import React from 'react'
import Navbar from '../components/navbar'
import backgroundImage from '../assets/sermon_bg.jpg'
import { useState, useEffect } from 'react'
import { fetchSermons } from '../components/contentfulFiles'
import sermonImage from '../assets/sermons_pic.jpg'
import '../styles/sermons.css'
import withScroll from '../components/scroll'

const Sermons = ({ sectionRef, handleScroll }) => {
    const [sermons, setSermons] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sermonsPerPage] = useState(5);

    useEffect(() => {
      const getSermons = async () => {
        try {
          const fetchedSermons = await fetchSermons();
          setSermons(fetchedSermons);
        } catch (error) {
          console.error("Error fetching sermons:", error);
        }
      };
  
      getSermons();
    }, []);
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const filteredSermons = sermons.filter((sermon) =>
        sermon.fields.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sermon.fields.speaker.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const indexOfLastSermon = currentPage * sermonsPerPage;
      const indexOfFirstSermon = indexOfLastSermon - sermonsPerPage;
      const currentSermons = filteredSermons.slice(indexOfFirstSermon, indexOfLastSermon);
    
      const paginate = (pageNumber) => setCurrentPage(pageNumber);
      const sortedSermons = [...currentSermons].sort((a, b) => new Date(b.fields.sorttime) - new Date(a.fields.sorttime));
      return (
        <div className='sermons-page'>
          <Navbar />
          <main>
            <section
              className="hero"
              style={{
                backgroundImage: `url(${backgroundImage})`,
              }}
            >
              <div className="hero-content">
                <h1>Listen to the <br />word of God</h1>
              </div>
              <div className="hero-buttons">
                <button className="btn" onClick={handleScroll}>View our recent sermons</button>
              </div>
            </section>
    
            <section ref={sectionRef} className="sermons-container">
              <input
                type="text"
                placeholder="Search sermons..."
                value={searchTerm}
                onChange={handleSearch}
                className="search-bar"
              />
              {sortedSermons.length > 0 ? (
                sortedSermons.map((sermon, index) => (
                  <div key={index} className="sermon">
                    <div className="sermon-image-container">
                      <img src={sermonImage} alt="Sermon" className="sermon-image"/>
                    </div>
                    <div className="sermon-details">
                      <p className="sermon-date">{sermon.fields.date}</p>
                      <h2 className="sermon-title">{sermon.fields.title}</h2>
                      <p className="sermon-passage"><strong>Passage:</strong> {sermon.fields.biblePassage}</p>
                      <p className="sermon-speaker"><strong>Speaker:</strong> {sermon.fields.speaker}</p>
                      <a href={sermon.fields.url} target="_blank" rel="noopener noreferrer" className="watch-button">Watch</a>
                    </div>
                  </div>
                ))
              ) : (
                <p>No sermons available</p>
              )}
            </section>
    
            <div className="pagination">
              {[...Array(Math.ceil(filteredSermons.length / sermonsPerPage)).keys()].map(number => (
                <button key={number} onClick={() => paginate(number + 1)} className="page-number">
                  {number + 1}
                </button>
              ))}
            </div>
          </main>
        </div>
      );
    }
    
    export default withScroll(Sermons);