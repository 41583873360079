import { createClient } from 'contentful';
import { createClient as createManagementClient } from 'contentful-management';

const client = createClient({
  space: 'f63hayxm91nf',
  accessToken: 'wp7xWN6qL1nW7_dWNI8OoPAtAVQT75riuibpMkWP4AE'
});
const managementClient = createManagementClient({
  accessToken: 'wp7xWN6qL1nW7_dWNI8OoPAtAVQT75riuibpMkWP4AE' // Replace with your Contentful Management API access token
});
export default client;
export const fetchMinistries = async () => {
  const entries = await client.getEntries({ content_type: 'ministryPage' });
  return entries.items;
};

export const fetchEvents = async () => {
  const response = await client.getEntries({
    content_type: 'events'
  });

  return response.items;
};

export const fetchImages = async () => {
  const entries = await client.getEntries({ content_type: 'slideshowImage' });
  return entries.items;
};
export const uploadAsset = async (file) => {
  const space = await managementClient.getSpace('f63hayxm91nf');
  const environment = await space.getEnvironment('master');
  const asset = await environment.createAssetFromFiles({
    fields: {
      title: {
        'en-US': file.name
      },
      file: {
        'en-US': {
          contentType: file.type,
          fileName: file.name,
          file: file
        }
      }
    }
  });

  await asset.processForAllLocales();
  await asset.publish();
  return asset;
};

export const createEntry = async (assetId, title) => {
  const space = await managementClient.getSpace('f63hayxm91nf');
  const environment = await space.getEnvironment('master');

  const entry = await environment.createEntry('slideshowImage', {
    fields: {
      title: {
        'en-US': title
      },
      image: {
        'en-US': {
          sys: {
            id: assetId,
            linkType: 'Asset',
            type: 'Link'
          }
        }
      }
    }
  });

  await entry.publish();
  return entry;
};
export const fetchAnnouncements = async () => {
  const response = await client.getEntries({
    content_type: 'announcements'
  });
  return response.items;
};
export const fetchSermons = async () => {
  const response = await client.getEntries({
    content_type: 'sermons'
  });

  return response.items;
};
export const fetchPodcasts = async () => {
  const response = await client.getEntries({
    content_type: 'podcast'
  });

  return response.items;
}