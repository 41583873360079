import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; //used to link pages
import Home from './pages/home';
import About from './pages/About';
import Events from './pages/events';
import Contact from './pages/Contact';
import Give from './pages/Give';
import Ministries from './pages/Minstries';
import Bulletin from './pages/bulletin';
import Ministrypage from './pages/ministrypage';
import BulkImageUploader from './pages/imageUpload';
import Announcements from './pages/announcements';
import Sermons from './pages/sermons';
import Podcasts from './pages/podcasts';
import Podcastcontent from './pages/podcastpage';

function App() {
  return (
    <Router>
    <div className="App">
      <Routes> 
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/ministries" element={<Ministries />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contact" element={<Contact />} />
        <Route path ="/online-giving" element={<Give />} />
        <Route path ="/bulletin" element={<Bulletin />} />
        <Route path ="/ministry/:urlname" element={<Ministrypage />} />
        <Route path ="/image-upload" element={<BulkImageUploader />} />
        <Route path ="/announcements" element={<Announcements />} />
        <Route path ="/sermons" element={<Sermons />} />
        <Route path ="/podcasts" element={<Podcasts />} />
        <Route path="/podcasts/:titleSlug" element={<Podcastcontent />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
