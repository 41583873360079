import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'; //React Big Calendar
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css'; //React Big Calendar CSS
import { fetchEvents } from '../components/contentfulFiles'; 
const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
    const [events, setEvents] = useState([]);
  
    useEffect(() => {
      const getEvents = async () => {
        const fetchedEvents = await fetchEvents();
        const formattedEvents = fetchedEvents.map(event => ({
          title: event.fields.eventName,
          start: new Date(event.fields.startTime),
          end: new Date(event.fields.endTime),
          allDay: event.fields.allDay || false,
        }));
        setEvents(formattedEvents);
      };
      getEvents();
    }, []);
  
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
};

export default CalendarComponent;
