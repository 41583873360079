import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import backgroundImage from "../assets/biblepray.png";
import "../styles/ministries.css";
import { fetchMinistries } from '../components/contentfulFiles';
import { Link } from 'react-router-dom';
import Prayer from '../components/prayer';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import withScroll from '../components/scroll';

const Ministries = ({ sectionRef, handleScroll }) => {
  const [ministries, setMinistries] = useState([]);

  useEffect(() => {
    const getMinistries = async () => {
      const ministriesData = await fetchMinistries();
      setMinistries(ministriesData);
    };

    getMinistries();
  }, []);

  return (
    <div className="ministries">
      <Helmet>
        <title>Ministries - Explore our faith ministries</title>
      </Helmet>
      <Navbar />
      <main> 
        <section
          className="hero"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="hero-content">
            <h1>
              Explore our faith
              <br />
              ministries
            </h1>
          </div>
          <div className="hero-buttons">
          <div className="hero-buttons">
            <button className="btn-ministries" onClick={handleScroll}>Explore</button>
            <button className="btn-ministries secondary">Reach out to us</button>
          </div>
          </div>
        </section>
        <section ref={sectionRef} className="ministries-list">
          <h1>Ministries Of God</h1>
          <div className="ministries-grid">
            {ministries.map((ministry) => (
              <div key={ministry.sys.id} className="ministry-item">
                <Link to={`/ministry/${ministry.fields.urlname}`}>
                  <img src={ministry.fields.minimage.fields.file.url} alt={ministry.fields.title} />
                </Link>
                <Link to={`/ministry/${ministry.fields.urlname}`}>
                  <h2>{ministry.fields.title}</h2>
                </Link>
              </div>
            ))}
          </div>
        </section>
        <Prayer />
      </main>
      <Footer />
    </div>
  );
};

export default withScroll(Ministries);
