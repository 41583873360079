// home.js
import React, { useEffect, useState, useRef } from "react";
import Navbar from "../components/navbar";
import Prayer from "../components/prayer.js";
import backgroundImage from "../assets/pray.png";
import "../styles/Home.css";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import serviceImage from "../assets/service_bg.png";
import worshipImage1 from "../assets/wors.jpg";
import worshipImage2 from "../assets/new.jpg";
import { fetchEvents } from "../components/contentfulFiles";
import SwiperComponent from "../components/swiperComponent";
import withScroll from "../components/scroll";
import Slideshow from "../components/slideshow.js";
import { fetchAnnouncements } from "../components/contentfulFiles";
import { Helmet } from "react-helmet";

const Home = ({ sectionRef, handleScroll }) => {
  const [events, setEvents] = useState([]);
  const [importantEvents, setImportantEvents] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const fetchedEvents = await fetchEvents();
      setEvents(fetchedEvents);
      const important = fetchedEvents.filter(
        (event) => event.fields.bigEvent === true
      );
      setImportantEvents(important);

      const fetchedAnnouncements = await fetchAnnouncements();
      const recentAnnouncements = fetchedAnnouncements.slice(0, 5); // Get the five most recent announcements
      setAnnouncements(recentAnnouncements);
    };
    getData();
  }, []);
  return (
    <div className="home">
      <Helmet>
        <title>Home - God's Family</title>
      </Helmet>
      <Navbar />
      <main>
        <section
          className="hero"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="hero-content">
            <h1>
              Calvary First Nigerian
              <br />
              Seventh-day Adventist Church
            </h1>
          </div>
          <div className="hero-buttons">
            <button className="btn" onClick={handleScroll}>
              Worship with us
            </button>
            <Link to="/sermons" className="btn secondary">
              Latest Sermons
            </Link>
            {/* <button className="btn secondary">Latest Sermons</button> */}
          </div>
        </section>
        <section className="quote">
          <p>
            “Welcome to the Calvary First Nigerian SDA Church, 778 Lexington
            Avenue, Brooklyn, NY 11221. We are a Christian community and would
            love to have you join our family. To learn more about what we
            believe you can visit our About Us page. Please join us for Bible
            study, worship, and prayer.”
          </p>
        </section>
        <section className="announcements">
          <h2>Announcements</h2>
          <div className="marquee">
            <span>
              {announcements.map((announcement, index) => (
                <span key={index}>
                  {announcement.fields.text} &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ))}
            </span>
          </div>
          <Link to="/announcements" className="button">
            View All Announcements
          </Link>
        </section>
        <section className="bulletin">
          <h2>Bulletin</h2>
          <p>
            Stay informed and inspired—download our bulletin for the latest
            news, events, and messages from our community.
          </p>
          <Link to="/bulletin" className="button">
            View this week's Bulletin Here.
          </Link>
        </section>
        <section
          ref={sectionRef}
          className="services"
          style={{
            backgroundImage: `url(${serviceImage})`,
          }}
        >
          <div className="service-info">
            <div className="service-text">
              <h1>Worship with us</h1>
              <h2>Midweek Prayer</h2>
              <p>Wednesday, 7pm-8pm</p>
              <h2>Sabbath School</h2>
              <p>Saturdays, 9:30am-11am</p>
              <h2>Divine Service</h2>
              <p>Saturdays, 11:30am-1pm</p>
              <h1>Find us at</h1>
              <h2>Location</h2>
              <p>778 Lexington Ave, Brooklyn, NY 11221</p>
            </div>
            <div className="service-images-container">
              <img
                src={worshipImage1}
                alt="Worship Service 1"
                className="service-image-1"
              />
              <img
                src={worshipImage2}
                alt="Worship Service 2"
                className="service-image-2"
              />
            </div>
          </div>
        </section>
        <section className="live-services">
          <h2>Watch our services live!</h2>
          <div className="live-stream">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/GnezflSoJ_w?si=jkmjcMue9eJ6_W3N"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </section>
        <section className="home-events">
          <h2>Jesus filled events</h2>
          {importantEvents.length > 0 ? (
            <SwiperComponent events={importantEvents} />
          ) : (
            <p>
              No important events available at the moment. Please check back
              later.
            </p>
          )}
        </section>
        <section className="slideshow">
          <Slideshow />
        </section>
        <Prayer />
      </main>
      <Footer />
    </div>
  );
};

export default withScroll(Home);
